.path1 {
  box-sizing: border-box;
  color: rgb(73, 80, 87);
  fill: rgb(250, 82, 82);
  font-size: 14px;
}

.path2 {
  box-sizing: border-box;
  color: rgb(73, 80, 87);
  fill: rgb(73, 80, 87);
  font-size: 14px;
}