.image-styles-custom {
  transform: scale(0.90);
  transition-timing-function: cubic-bezier(0.46, 0.16, 0.38, 0.84);
}

.image-styles-custom:hover {
  transform: scale(0.80);
}

.cell-image {
  width: 31;
  height: 31;
  object-fit: cover;
  border-radius: 50%;
}

.card-project-img {
  object-fit: cover !important;
  border-radius: 4px !important;
}

.detail-image {
  object-fit: cover;
  height: 320px;
  width: 100%;
}