.card-game-img {
    height: 18rem;
    object-fit: cover;
}

.seperator {
    width: 100%;
}

.controls-wrapper {
    width: 100%;
}

.carousel-wrapper {
    width: 100%;
}

.rec-carousel-item:focus {
    outline: none;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}