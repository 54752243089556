.uploading-file-in-progress {
  position: relative;
}

.uploading-file-in-progress::after {
  display: inline-block;
  content: "";
  width: 14px;
  height: 14px;
  vertical-align: -0.125em;
  border: 3px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  position: absolute;
  right: 9px;
  top: 9px;
}
