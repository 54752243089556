.input-styles-custom {
  width: 100px;
  height: 15px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: white;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 10px;
  transition: width 0.4s ease-in-out;
  /* margin-left: 10px; */
  /* font-size: 16px; */
  /* background-image: url('searchicon.png'); */
  /* background-position: 10px 10px;  */
}

.input-styles-custom:focus {
  width: 140px;
  border: 2px solid #ccc;
}