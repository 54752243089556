/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 1040; /* Behind the navbar */
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
  box-shadow: 0 0px 6px 2px rgb(218 218 253 / 65%), 0 0px 6px 2px rgb(206 206 238 / 54%);
  overflow-y: auto;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 56px;
  }
}

.navbar-toggler-icon {
  filter: invert(1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #bababa;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}
